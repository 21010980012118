var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":_vm.dialogWidth,"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('app.buttons.add'))+" "+_vm._s(_vm.$t('app.jugadores.single'))+" "+_vm._s(_vm.$t('app.general.to'))+" "+_vm._s(_vm.$t('app.equipos.single'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":_vm.saveItem}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.save'))+" ")])],1)],1),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('form',[_c('v-list',{attrs:{"three-line":""}},[_vm._l((_vm.items),function(item,index){return _c('v-row',{key:("equipo-" + index)},[_c('v-col',{staticClass:"d-flex flex-shrink-0 flex-grow-1"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('app.equipos.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.equipos.single'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{key:("equipos-" + index),attrs:{"placeholder":_vm.$t('app.equipos.single'),"items":_vm.equipos,"item-text":"nombre","item-value":"id","error-messages":errors,"required":""},model:{value:(item.equipo_id),callback:function ($$v) {_vm.$set(item, "equipo_id", $$v)},expression:"item.equipo_id"}})]}}],null,true)})],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex flex-shrink-0 flex-grow-1"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.posiciones.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.posiciones.single'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{key:("posiciones-" + index),attrs:{"placeholder":_vm.$t('app.posiciones.single'),"items":_vm.posiciones,"item-text":"nombre","item-value":"id","error-messages":errors,"required":""},model:{value:(item.posicion_id),callback:function ($$v) {_vm.$set(item, "posicion_id", $$v)},expression:"item.posicion_id"}})]}}],null,true)})],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex flex-shrink-1 flex-grow-0"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.captain')))]),_c('v-list-item-subtitle',[_c('v-switch',{staticClass:"pl-5",attrs:{"placeholder":_vm.$t('app.headers.captain'),"label":item.capitan ? _vm.$t('app.general.yes') : _vm.$t('app.general.no')},model:{value:(item.capitan),callback:function ($$v) {_vm.$set(item, "capitan", $$v)},expression:"item.capitan"}})],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex flex-shrink-1 flex-grow-0"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-btn',{attrs:{"icon":"","disabled":index === 0},on:{"click":function($event){return _vm.removeEquipo(index)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)],1)],1)],1)}),_c('v-list-item',[_c('v-list-item-content',[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.addEquipo}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" "+_vm._s(_vm.$t('app.buttons.add'))+" "+_vm._s(_vm.$t('app.equipos.single'))+" ")],1)],1)],1)],2)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }