<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
        >
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.add') }} {{ $t('app.jugadores.single') }} {{ $t('app.general.to') }} {{ $t('app.equipos.single') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
        ref="observer"
        v-slot="{ validate, reset }"
      >
        <form>
          <v-list
            three-line
          >
            <v-row
              v-for="(item, index) in items"
              :key="`equipo-${index}`"
            >
              <v-col
                class="d-flex flex-shrink-0 flex-grow-1"
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ $t('app.equipos.single') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('app.equipos.single')"
                        rules="required"
                      >
                        <v-autocomplete
                          v-model="item.equipo_id"
                          :placeholder="$t('app.equipos.single')"
                          :items="equipos"
                          item-text="nombre"
                          item-value="id"
                          :error-messages="errors"
                          :key="`equipos-${index}`"
                          required
                        ></v-autocomplete>
                      </validation-provider>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col
                class="d-flex flex-shrink-0 flex-grow-1"
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('app.posiciones.single') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('app.posiciones.single')"
                        rules="required"
                      >
                        <v-autocomplete
                          v-model="item.posicion_id"
                          :placeholder="$t('app.posiciones.single')"
                          :items="posiciones"
                          item-text="nombre"
                          item-value="id"
                          :error-messages="errors"
                          :key="`posiciones-${index}`"
                          required
                        ></v-autocomplete>
                      </validation-provider>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col
                class="d-flex flex-shrink-1 flex-grow-0"
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('app.headers.captain') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        class="pl-5"
                        v-model="item.capitan"
                        :placeholder="$t('app.headers.captain')"
                        :label="item.capitan ? $t('app.general.yes') : $t('app.general.no')"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col
                class="d-flex flex-shrink-1 flex-grow-0"
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-btn
                      icon
                      :disabled="index === 0"
                      @click="removeEquipo(index)"
                    >
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-list-item>
              <v-list-item-content>
                <v-btn
                  outlined
                  color="primary"
                  @click="addEquipo"
                >
                  <v-icon left>
                    mdi-plus-circle
                  </v-icon>

                  {{ $t('app.buttons.add') }} {{ $t('app.equipos.single') }}
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </form>
      </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'JugadoresAddToTeam',
  data () {
    return {
      items: [{
        equipo_id: null,
        posicion_id:  null,
        capitan: false,
        jugador_id: null,
      }],
      dialog: false,
      jugador: false,
      equipos: []
    }
  },
  computed: {
    equiposFiltered () {
      return this.equipos.filter(item => !this.items.map(item => item.equipo_id).includes(item.id))
    }
  },
  mounted () {
    EventBus.$on('jugadores-add-to-team', async (params) => {
      this.toggleLoader()
      this.equipos = await this.getEquipos()
      this.posiciones = await this.getPosiciones()
      this.jugador = params.jugador

      this.toggleLoader()
      this.dialog = true
    })
  },
  methods: {
    async saveItem () {
      this.$refs.observer.validate()
      .then(async success => {
        if (!success) return

        this.toggleLoader()

        const params = {
          equipos: this.items.map(item => {
            item.jugador_id = this.jugador.id
            return item
          }),
          jugador_id: this.jugador.id
        }

        await this.$http.post(route('v1/player/add-to-team'), params)
        .then(response => {
          if (response.body.code === 200) {
            this.processSuccess(response)

            this.items = [{
              equipo_id: null,
              posicion_id:  null,
              capitan: false,
              jugador_id: null,
            }]

            requestAnimationFrame(() => {
              this.$refs.observer.reset()
            })

            this.dialog = false
            EventBus.$emit('reload-items')
          } else {
            this.processError(response)
          }
        }, error => this.processError(error))

        this.toggleLoader()
      })
    },
    addEquipo () {
      this.items.push({
        equipo_id: null,
        posicion_id:  null,
        capitan: false,
        jugador_id: null,
      })
    },
    removeEquipo (index) {
      this.items.splice(index, 1)
    }
  }
}
</script>
